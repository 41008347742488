<template>
  <div class="cont-404">
    <div class="cont-in">
			<img class="img" src="../assets/image/404.jpg" alt="">
		</div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {

    }
  },
  mounted () {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.cont-404{
  min-height: 100vh;
  display: flex;
  align-items:center;
	background: #04aae9;
}
.img{
	display: block;
	width: 100%;
}

</style>
